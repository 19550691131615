<template>
  <div>
    <page-title class="emission-title mb-0" :title="$t('dashboard_detail.label_emissions_by_site')" />
    <page-title class="emission-sub-title" :title="titleText" />
    <div class="fix-top" id="headFix" :style="{'left': headerLeft}">
      <div class="container pl-40-px list-filter">
        <div class="row filter-items">
          <mix-label-pull-down-menu
            type="type_select"
            v-model="type_select"
            :label="$t('dashboard_detail.pulldown_select_display')"
            :items="type_list"
            :initialValue="type_select"
            width="100%"
            class-list="one-line border-left border-right"
            @updateData="updateData"
            idBox='1'
          />
          <mix-label-pull-down-menu
            type="period_select"
            :label="$t('dashboard_detail.pulldown_select_period')"
            v-model="period_select"
            :items="period_list"
            :initialValue="period_select"
            width="100%"
            min-width="160px"
            class-list="one-line border-right"
            @updateData="updateData"
            idBox='2'
          />
        </div>
      </div>
    </div>
    <div class="emission-by-category">
      <div
        class="category-table main-table custom-table center-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="dbCustomViews"
          :init-grid="flexInitialized"
          :grid-columns="gridColumns"
          :totalData="totalData - 1"
          :rowFocus="2"
          :showFocus="false"
          :lastedRow="lastedRow"
          :allow-add-new="false"
          :isSetting="true"
          @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ROUTES } from "@/router/constants";
import DataTable from "@/components/category/data-table";
import { formatDateTime } from "@/utils/datetimeFormat";
import PageTitle from "@/components/pageTitle/pageTitle.vue";
import { CollectionView } from "@mescius/wijmo";
import { getStartMonth } from "@/api/duration";
import { getEmissionsByPeriod, getDetailScopeBranch } from "@/api/dashboard";
import webStorage from "@/utils/webStorage";
import { DESCRIPTIONS } from "@/constants/descriptions";
import { numberFormat } from "@/filters/number";
import { KEYS_CODE } from "@/constants/keyboard";
import MixLabelPullDownMenu from "@/components/pulldown/MixLabelPullDownMenu";
import { $_helper_isNumberType, formatValue, math } from '@/concerns/newRegisterData/wijmo.helper';
import { preparePhysicalList, convertMonthCalendar } from '@/utils/registerData';
import { formatNumberByConditions } from '@/utils/convertNumber';
import { setMinMaxSizeColumns, getWidthByTextContent } from '@/utils/calcTextWidth';
import { GICS_PULLDOWN } from '@/constants/registerData';
import { formatValueUnit } from '@/concerns/chart';
import { UNIT_SETTING } from '@/constants/dashboard';
export default {
  components: {
    DataTable,
    PageTitle,
    MixLabelPullDownMenu
  },
  data() {
    return {
      diffText           : "その他",
      selectionButtonText: "選択",
      totalData          : null,
      context            : null,
      items              : null,
      activeRow          : null,
      types              : {
        category: "Categories",
        scope   : "Scopes"
      },
      dbCustomsFlex      : null,
      selectedItem       : "",
      gridColumns        : [],
      dbCustoms          : null,
      dbCustomViews      : null,
      unit               : "t-CO2/含有量t,t-CO2/kL,t-CO2/tkm,t-CO2/個,t-CO2/台".split(","),
      scope              : [1, 2, 3],
      category           : [1, 2, 3, 4, 5, 6],
      restoreSelection   : null,
      pastingData        : null,
      scope_select       : "",
      type_select        : "",
      type_list          : [
        {
          name : this.$t("dashboard_detail.label_by_registration_year"),
          value: 1
        },
        {
          name : this.$t("dashboard_detail.label_semi-annual"),
          value: 3
        },
        {
          name : this.$t("dashboard_detail.label_quarterly"),
          value: 4
        },
        {
          name : this.$t("dashboard_detail.label_by_months"),
          value: 2
        },
      ],
      period_select      : "",
      period_list        : [],
      dataCategory       : [],
      branch             : [],
      category_id        : "",
      resizeTimer        : "",
      titleText          : "",
      lastedRow          : {},
      layer              : [],
      layerIndex         : {
        1: "business_name",
        2: "country",
        3: "layer_3",
        4: "layer_4",
        5: "layer_5",
        6: "layer_6"
      },
      selectedFilterColumn: null,
      startMonth: null,
      order: null,
      flexgrid: null,
    };
  },
  async mounted() {
    // if (!this.$route.query.categoryName) {
    //   await this.$router.push("404");
    // }
    this.branch      = this.$store.state.dashboard.branchIds;
    this.scope_select = Number(this.$route.query.scope);
    this.type_select  = Number(this.$route.query.type);
    this.order = Number(this.$route.query.order);
    this.category_id = this.$route.query.category ? this.$route.query.category : null;
    await getStartMonth(this.contractor).then(res => {
      this.startMonth = res.data.start_month;
      if (this.type_select !== 2) {
        this.preparePeriodList();
      }
    })
    if (this.type_select === 1) {
      this.period_select = Number(this.$route.query.year);
    } else if (this.type_select === 2) {
      this.period_select = {
        month: Number(this.$route.query.month),
        year: Number(this.$route.query.year),
      }
    } else {
      this.period_select = `${this.order}-${Number(this.$route.query.year)}`;
    }

    let items = [
      {
        text    : this.$t('dashboard_detail.hyperlink_home'),
        disabled: false,
        href    : ROUTES.DASHBOARD
      },
      {
        text    : this.$t('dashboard_detail.title_emissions_by_location'),
        disabled: true,
        href    : ROUTES.DASHBOARD
      },
      {
        text    : "Scope" + this.$route.query.scope,
        disabled: true,
        href    : ROUTES.DASHBOARD
      },
      {
        text    : this.getPosition,
        disabled: true,
        href    : ROUTES.DASHBOARD
      },
      {
        text    :
          parseInt(this.$route.query.scope) === 3
            ? this.category_id + ". " + this.$route.query.categoryName
            : this.$route.query.categoryName,
        disabled: true,
        href    : ROUTES.DASHBOARD
      },
      {
        text    : webStorage.get("title"),
        disabled: true,
        href    : ROUTES.DASHBOARD
      }
    ];

    let categoryName =
          parseInt(this.$route.query.scope) === 3
            ? this.category_id + ". " + this.$route.query.categoryName
            : this.$route.query.categoryName;
    this.titleText   = this.$t("dashboard_detail.scope_title", {scope: this.$route.query.scope}) + " > " + categoryName + " > " + webStorage.get("title");
    if(this.$route.query.scope === '4') {
      this.titleText  = this.$t('list_emissions.title_scope_others') + " > " + categoryName + " > " + webStorage.get("title");
      items = items.filter(bread => bread.text !== "Scope" + this.$route.query.scope)
    }
    this.updateBreadCrumb(items);
  },
  computed: {
    ...mapState("userData", ["contractor", "currentUser"]),
    ...mapState("commonApp", ["loadMore", "isFilter", "isExpand"]),
    ...mapState("registerData", ["isFullScreen"]),
    ...mapState('settingsDashboard', ['itemsSettings']),
    getPosition() {
      if (parseInt(this.$route.query.scope) <= 3) {
        return DESCRIPTIONS["scope" + parseInt(this.$route.query.scope)]["category1"]["position"];
      }

      return DESCRIPTIONS["scope" + parseInt(this.$route.query.scope)][
      "category" + parseInt(this.$route.query?.category) || 1
        ]["position"];
    },
    headerLeft() {
      let leftWidth = '0px';
      if(!this.checkMobile()) {
        leftWidth = this.isExpand ? '236px' : '73px';
      }
      return leftWidth
    },
    getUnitName() {
      return this.itemsSettings[this.contractor]?.unitName || 't-CO2';
    },
    getType() {
      return this.itemsSettings[this.contractor]?.type || 'market';
    }
  },
  methods : {
    checkMobile() {
      return window.innerWidth < 1024;
    },
    getGridColumn() {
      const headerUnit = this.$i18n.locale === 'ar-AE' && this.getUnitName === 'kg-CO2' ? this.$t("dashboard_detail.table_emissions_kg_co2") : this.$t("dashboard_detail.tabel_emissions", { unit: this.getUnitName })
      return [
        {
          header: "#",
          binding: "id",
          allowSorting: false,
          isReadOnly: true,
          visible: false
        },
        {
          header      : this.$t("dashboard_detail.tabel_organization_division"),
          binding     : "organizational_division",
          allowSorting: false,
          isReadOnly  : true,
          minWidth    : getWidthByTextContent(this.$t("dashboard_detail.tabel_organization_division")),
          maxWidth    : 400,
          cssClass    : "auto-increment format-height",
          cssClassAll : "single-row",
          wordWrap    : true,
        },
        {
          header      : this.$t("dashboard_detail.tabel_corporate_name"),
          binding     : "company_name",
          minWidth    : getWidthByTextContent(this.$t("dashboard_detail.tabel_corporate_name")),
          maxWidth    : 400,
          allowSorting: false,
          isReadOnly  : true,
          align       : "left",
          cssClass    : "auto-increment format-height",
          cssClassAll : "single-row",
          wordWrap    : true,
        },
        ...this.layer,
        {
          header      : headerUnit,
          binding     : "total_emission_branch",
          minWidth    : getWidthByTextContent(headerUnit),
          maxWidth    : 960,
          allowSorting: false,
          isReadOnly  : true,
          align       : "right",
          cssClass    : "auto-increment format-height",
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true,
        },
        {
          header      : this.$t("dashboard_detail.tabel_ratio_to_all_locations"),
          binding     : "rate_all",
          minWidth    : getWidthByTextContent(this.$t("dashboard_detail.tabel_ratio_to_all_locations")),
          width: '*',
          allowSorting: false,
          isReadOnly  : true,
          align       : "right",
          // format: 'p*',
          cssClass   : "auto-increment format-height",
          cssClassAll: "no-tooltip single-row",
          wordWrap    : true,
        }
      ];
    },
    ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoadingTable"]),
    defineTable() {
      this.gridColumns   = this.getGridColumn();
      const dataTable    = this.dbCustoms?.map((item, index) => item && {
        ...item,
        index: index + 1
      });
      const formatItems = ['total_emission_branch', 'rate_all'];
      this.dbCustomViews = new CollectionView(dataTable, {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if (this.selectedFilterColumn === 'rate_all') {
            const valueA = a.split('%')[0].trim();
            const valueB = b.split('%')[0].trim();
            if ($_helper_isNumberType(valueA) && $_helper_isNumberType(valueB)) {
              const numA = Number(formatValue(valueA));
              const numB = Number(formatValue(valueB));
              return numA - numB;
            }
            return null;
          } else {
            if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
              const numA = Number(formatValue(a));
              const numB = Number(formatValue(b));
              return numA - numB;
            }
            return null;
          }
        }
      });
      this.dbCustomViews.currentItem = null;
      setMinMaxSizeColumns(this.flexgrid, dataTable);
    },
    flexInitialized(flexgrid) {
      this.flexgrid = flexgrid;
      flexgrid.hostElement.addEventListener(
        "keydown",
        (e) => {
          // console.log('keydown: ', e);
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(0, currentSelection.col);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, 1);
              flexgrid.selection     = cellRange;
            }
          }
        },
        false
      );
    },

    preprocessFetchedData(data) {
      let preprocessedData = [];
      const isTco2Unit = this.getUnitName === 't-CO2';
      data.forEach((element) => {
        const formattedEmission = formatValueUnit(element.total_emission_branch, isTco2Unit);
        let itemData = {
          id                       : element.id,
          business_name            : element.business_name,
          company_name             : element.company_name,
          country                  : element.country,
          organizational_division  : element.organizational_division,
          rate_all                 : formatNumberByConditions(element.rate_all, { formatUnit: UNIT_SETTING.PERCENT }) + " %",
          total_emission_all_branch: element.total_emission_all_branch,
          total_emission_branch    : formatNumberByConditions(formattedEmission, { formatUnit: UNIT_SETTING.CO2 }),
          layer_3                  : element.layer_3,
          layer_4                  : element.layer_4,
          layer_5                  : element.layer_5,
          layer_6                  : element.layer_6
        };
        preprocessedData.push(itemData);
      });
      return preprocessedData;
    },
    getWidthByText(string) {
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width      = "auto";
      text.style.position   = "absolute";
      text.style.whiteSpace = "no-wrap";
      text.style.font       = "Source Han Sans";
      text.style.fontSize   = 13 + "px";
      text.innerHTML        = string;
      const width           = text.clientWidth;
      document.body.removeChild(text);
      return width + 30 + 24 + 34; // width of button in header
    },
    async fetchDbCustoms() {
      this.lastedRow = {};
      try {
        const dbCustoms = await this.callApiFetchData();
        if (dbCustoms) {
          if (!dbCustoms.display_scope_3 && parseInt(this.scope_select) === 3) {
            await this.$router.push("404");
          }
          this.totalData = dbCustoms.length;
          this.dbCustoms = await this.preprocessFetchedData(dbCustoms.detail_scope_branch);
          this.layer     = dbCustoms.layer?.map(item => {
            return {
              header      : item.layer_name,
              visible     : true,
              binding     : this.layerIndex[item.layer_index],
              minWidth    : 90,
              maxWidth    : 980,
              allowSorting: false,
              isRequired  : false,
              wordWrap    : true,
              isReadOnly  : true,
              cssClass    : "auto-increment format-height",
              cssClassAll : "single-row"
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async callApiFetchData() {
      const isMarket = (this.getType === 'market') ? 1 : 0;
      let item_name = webStorage.get("title") === this.$t("dashboard_detail.tabel_ratio_to_all_locations") ? "" : webStorage.get("title");
      if (GICS_PULLDOWN.find(item => item.value === item_name)?.key) {
        item_name = GICS_PULLDOWN.find(item => item.value === item_name)?.key;
      }
      if( this.$route.query.type_credit_id) item_name = this.$route.query.type_credit_id;
      if (this.type_select == 1) {
        if (this.type_select && this.period_select && this.branch && this.scope_select) {
          return await getDetailScopeBranch({
            type       : this.type_select,
            year: this.period_select,
            branch_id  : this.branch,
            scope      : this.scope_select,
            category   : this.scope_select == 3 ? this.category_id : '',
            contractor_id: this.contractor,
            item_name,
            isMarket
          });
        }
      } else if (this.type_select === 2) {
        return await getDetailScopeBranch({
          type     : this.type_select,
          month    : Number(this.period_select.month),
          year     : this.period_select.year,
          branch_id: this.branch,
          category : this.scope_select == 3 ? this.category_id : '',
          scope    : this.scope_select,
          contractor_id: this.contractor,
          item_name,
          isMarket
        });
      } else {
        return await getDetailScopeBranch({
          type     : this.type_select,
          order    : this.period_select.split('-')[0],
          year     : this.period_select.split('-')[1],
          branch_id: this.branch,
          category : this.scope_select == 3 ? this.category_id : '',
          scope    : this.scope_select,
          contractor_id: this.contractor,
          item_name,
          isMarket
        });
      }
    },

    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    getDurationMonth() {
      const contractor_id = this.$store.state.userData.contractor;
      const type          = 1;
      const params        = {
        contractor_id,
        type
      };
      getEmissionsByPeriod(params).then((res) => {
        // this.branch = res.branch_ids;
        let charactor;
        const localeLang = this.$i18n.locale
        switch (localeLang) {
          case 'en':
            charactor = ' ';
            break;
          case 'ja':
            charactor = '';
            break;
          default:
            charactor = '/'
            break;
        }
        this.period_list = res.data[this.getType].sort((a, b) => {
          if (a.year === b.year) {
            return a.month - b.month;
          } else {
            return b.year - a.year;
          }
        }).map((item) => {
          let name = ''
          if(localeLang !== 'vi') {
            name = this.$t('register_data.year_calendar_select', {year: item.year}) + charactor + this.$t('register_data.month_select', { month: convertMonthCalendar(numberFormat(item.month))})
          } else {
            // custome only vietnamese
            name = 'Tháng ' + this.$t('register_data.month_select', { month: convertMonthCalendar(numberFormat(item.month))}) + charactor + 'năm ' + this.$t('register_data.year_calendar_select', {year: item.year})
          }
          return {
            value: {
              month: Number(item.month),
              year : item.year
            },
            name: name
          };
        });
      });
    },
    getWidth() {
      if (window.innerWidth < 768) {
        return false;
      }
      return true;
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    preparePeriodList() {
      // this.period_list
      if (this.type_select == 1) {
        this.period_list = preparePhysicalList('year', this.startMonth);
      } else if (this.type_select == 2) {
        this.getDurationMonth();
      } else if (this.type_select == 3) {
        this.period_list = preparePhysicalList('half-year', this.startMonth);
      } else  {
        this.period_list = preparePhysicalList('quarter-year', this.startMonth);
      }
    },
    updateData(data, type) {
      this[type] = data;
    }
  },
  watch   : {
    type_select() {
      this.preparePeriodList();
      this.period_select = "";
    },
    period_select(newValue, _) {
      if (newValue) {
        this.actionUpdateIsLoadingTable(true)
        this.fetchDbCustoms().then(() => {
          this.defineTable();
          this.actionUpdateIsLoadingTable(false)
        }).catch(() => {
          this.actionUpdateIsLoadingTable(false)
        });
      }
    }
  },
};
</script>

<style scoped lang="scss">
.emission-title {
  margin-bottom: 0;
  padding: 35px 0 0 0;
}

.emission-sub-title {
  padding: 0;
}

.emission-select {
  position: relative;
  z-index: 1;
  display: block;
  padding: 0;
  filter: drop-shadow(0px 65px 110px rgba(160, 181, 186, 0.0767396)) drop-shadow(0px 14.5186px 24.5699px rgba(160, 181, 186, 0.11326)) drop-shadow(0px 4.32257px 7.31511px rgba(160, 181, 186, 0.19));

  margin-bottom: 40px;

  .block {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 8px;

    .title {
      font-family: 'Source Han Sans' !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 24px;
      letter-spacing: 0.03em !important;

      width: 100px;
      color: #404d50;
      margin: 8px 8px 8px 0;
    }
  }

  .hr-block {
    display: none;

    .hr-style {
      width: 20px;
      align-self: center;
      height: 1px;
      background: rgba(42, 42, 48, 0.1);
      border: none;
      margin-top: auto;
      margin-bottom: auto;
      flex-grow: unset;
    }
  }
}

.emission-by-category {
  .custom-table {
    &.full-screen {
      top: 127px
    }
  }
}

@include tablet {
  .emission-title {
    padding: 76px 40px 0;
  }

  .emission-sub-title {
    padding: 8px 40px 0;
  }

  .emission-select {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px 40px 0;
    filter: drop-shadow(0px 65px 110px rgba(160, 181, 186, 0.0767396)) drop-shadow(0px 14.5186px 24.5699px rgba(160, 181, 186, 0.11326)) drop-shadow(0px 4.32257px 7.31511px rgba(160, 181, 186, 0.19));
    margin-bottom: 0;

    .block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      margin-bottom: 48px;

      .title {
        font-family: 'Source Han Sans' !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px;
        letter-spacing: 0.03em !important;

        color: #404d50;
        margin: 0 0 8px;
      }
    }

    .hr-block {
      height: 40px;
    }
  }
  .emission-by-category {
    margin: 0 40px;
  }
}

.source-column {
  word-wrap: break-word;
}
.wj-flexgrid .wj-cell:not([wj-state-measuring]) button.wj-cell-maker {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 106px;
  height: 24px;
  background: #e4e6e7;
  border-radius: 4px;
  margin: 4px;

  /* Table/CellBtn */

  font-family: 'Source Han Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #99a6a9;
}

#headFix {
  transition: 0.1s ease-out;
  transition-duration: 0.1s;
}
#headFix.fix-top {
  width: 100%;
  display: block;
  z-index: 99;
  position: fixed;
  top: 84px;
  background: $monoOffWhite;
  right: 0;
  max-width: 100%;
  border-top: 1px solid rgba(42, 42, 48, 0.1);
  box-shadow: 0 4.322566509246826px 7.315112113952637px 0 rgba(160, 181, 186, 0.19),
  0 14.518600463867188px 24.5699405670166px 0 rgba(160, 181, 186, 0.11),
  0 65px 110px 0 rgba(160, 181, 186, 0.08);
  padding-left: 20px;
  padding-right: 20px;
  .border-right {
    border-right: 1px solid rgba(42, 42, 48, 0.1);
  }

  .border-left {
    border-left: 1px solid rgba(42, 42, 48, 0.1);
  }

  .list-filter {
    overflow: auto;
    white-space: nowrap;
  }

  .list-filter::-webkit-scrollbar {
    height: 7px;
  }

  .list-filter::-webkit-scrollbar-thumb:horizontal{
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background: #bfd4d9;
  }

  /* Track */
  .list-filter::-webkit-scrollbar-track:horizontal {
    background: #e3eded;
  }

  /* Handle on hover */
  .list-filter::-webkit-scrollbar-thumb:horizontal:hover {
    background: #7a8b8d;
  }

  .filter-items {
    display: inline-block;
    vertical-align: top;
    height: 43px;
  }

  .one-line {
    display: inline-block;
  }
}

@include desktop {

  #headFix.fix-top {
    padding-left: 0;
  }

  .border-left {
    border-left: 1px solid rgba(42, 42, 48, 0.1);
  }

  .pl-40-px {
    padding-left: 40px;
  }

  .list-filter {
    overflow: hidden !important;

    &:hover {
      overflow-x: auto !important;
    }
  }
}
</style>
